import 'expo-dev-client';

import { registerRootComponent } from 'expo';
import {
  ButtonText,
  Text,
  BadgeText,
  Heading,
  AccordionTitleText,
  AccordionContentText,
} from '@gluestack-ui/themed';
import { TextInput } from 'react-native';

Text.defaultProps = {};
Text.defaultProps.maxFontSizeMultiplier = 1.3;

TextInput.defaultProps = {};
TextInput.defaultProps.maxFontSizeMultiplier = 1.3;

ButtonText.defaultProps = {};
ButtonText.defaultProps.maxFontSizeMultiplier = 1.3;

BadgeText.defaultProps = {};
BadgeText.defaultProps.maxFontSizeMultiplier = 1.3;
Heading.defaultProps = {};
Heading.defaultProps.maxFontSizeMultiplier = 1.3;
AccordionTitleText.defaultProps = {};
AccordionTitleText.defaultProps.maxFontSizeMultiplier = 1.2;

AccordionContentText.defaultProps = {};
AccordionContentText.defaultProps.maxFontSizeMultiplier = 1.2;

import App from './App';

// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in the Expo client or in a native build,
// the environment is set up appropriately
registerRootComponent(App);
