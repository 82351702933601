import React, { useContext, useEffect } from 'react';
import {
  ScrollView,
  SafeAreaView,
  StyleSheet,
  View,
  Pressable,
  Platform,
  Linking,
} from 'react-native';
import {
  Button,
  Text,
  Box,
  VStack,
  Heading,
  HStack,
  Center,
  Avatar,
  Icon,
  AtSignIcon,
  ButtonText,
  CheckCircleIcon,
  ButtonGroup,
} from '@gluestack-ui/themed';
import { AuthContext } from '../AuthProvider';
import Loading from './shared/Loading';
import { useNavigation } from '@react-navigation/native';
import SelectCryptos from './shared/SelectCryptos';

export default function Settings() {
  const {
    user,
    marketApps,
    marketAppsLoading,
    logout,
    configuredCryptoSettings,
    rhSessionExpirationDate,
    rhEmail,
  } = useContext(AuthContext);
  const navigation = useNavigation();

  useEffect(() => {
    // init mount
    console.log('yooo redrawing');
  }, [rhEmail, marketApps]);

  return (
    <SafeAreaView style={styles.container}>
      {user && marketAppsLoading && (
        <Center>
          <Loading />
        </Center>
      )}

      {/* Setup a broker */}
      {user && !marketAppsLoading && marketApps.length === 0 && (
        <View style={styles.center}>
          <Text bold={true} color={'$primary500'}>
            CryptoPilot.app
          </Text>
          <Text>Select crypto/stocks broker</Text>

          <Pressable
            style={styles.pressable}
            onPress={() => navigation.navigate('RobinhoodLogin')}
          >
            <HStack style={styles.hStack}>
              <Heading style={styles.buttonText}>Robinhood Stocks</Heading>
            </HStack>
          </Pressable>
          {/* Trigger Robinhood auth flow */}
          <Pressable
            style={styles.pressable}
            onPress={() => navigation.navigate('RobinhoodApiLogin')}
          >
            <HStack style={styles.hStack}>
              <Heading style={styles.buttonText}>Robinhood Crypto</Heading>
            </HStack>
          </Pressable>

          {/* Trigger Bitso auth flow */}
          <Pressable
            style={[styles.pressable, styles.pressableMargin]}
            onPress={() => navigation.navigate('BitsoLogin')}
          >
            <HStack style={styles.hStack}>
              <Heading style={styles.buttonText}>Bitso</Heading>
            </HStack>
          </Pressable>

          {/* Trigger Bitso auth flow */}
          <Pressable
            style={[styles.pressable, styles.pressableMargin]}
            onPress={() => navigation.navigate('BinanceLogin')}
            mb={20}
          >
            <HStack style={styles.hStack}>
              <Heading style={styles.buttonText}>Binance</Heading>
            </HStack>
          </Pressable>

          <Heading>Step By Step Guides For Integration</Heading>
          <ButtonGroup>
            <Button
              onPress={() => navigation.navigate('RobinhoodGuide')}
              variant={'outline'}
            >
              <ButtonText>Robinhood</ButtonText>
            </Button>

            <Button
              onPress={() => navigation.navigate('BinanceGuide')}
              variant={'outline'}
            >
              <ButtonText>Binance</ButtonText>
            </Button>
            <Button
              onPress={() => navigation.navigate('BitsoGuide')}
              variant={'outline'}
            >
              <ButtonText>Bitso</ButtonText>
            </Button>
          </ButtonGroup>
        </View>
      )}

      {user && marketApps.length > 0 && configuredCryptoSettings && (
        <ScrollView>
          <Center>
            <HStack space="md">
              <Avatar bgColor="$indigo600">
                {/* User is imported from 'lucide-react-native' */}
                <Icon as={AtSignIcon} color="white" size="lg" />
              </Avatar>
              <VStack>
                <Heading>{user.email}</Heading>
              </VStack>
            </HStack>

            <VStack space={'4xl'}>
              <Box>
                {marketApps.includes('robinhood_crypto') ? (
                  <Box>
                    <HStack>
                      <Icon as={CheckCircleIcon} />
                      <Text>Robinhood Crypto</Text>
                    </HStack>

                    <Button
                      size={'lg'}
                      onPress={() => navigation.navigate('RobinhoodApiLogin')}
                    >
                      <ButtonText>Reset Robinhood Crypto API Key</ButtonText>
                    </Button>
                  </Box>
                ) : (
                  <Button
                    size={'lg'}
                    onPress={() => navigation.navigate('RobinhoodApiLogin')}
                  >
                    <ButtonText>Set Up Robinhood Crypto</ButtonText>
                  </Button>
                )}

                {marketApps.includes('robinhood_stocks') ? (
                  <Box>
                    <HStack>
                      <Icon as={CheckCircleIcon} />
                      <Text>
                        Robinhood Stocks - Session Expires:{' '}
                        {rhSessionExpirationDate
                          ? rhSessionExpirationDate.toLocaleDateString('en-US')
                          : ''}
                      </Text>
                    </HStack>

                    <Button
                      size={'lg'}
                      onPress={() => navigation.navigate('RobinhoodLogin')}
                    >
                      <ButtonText>Refresh Robinhood Stocks Session</ButtonText>
                    </Button>
                  </Box>
                ) : (
                  <Button
                    size={'lg'}
                    onPress={() => navigation.navigate('RobinhoodLogin')}
                  >
                    <ButtonText>Set Up Robinhood Stocks</ButtonText>
                  </Button>
                )}
              </Box>
              <Box>
                {marketApps.includes('bitso') ? (
                  <>
                    <HStack>
                      <Icon as={CheckCircleIcon} />
                      <Text>Bitso</Text>
                    </HStack>
                    <Button
                      size={'lg'}
                      onPress={() => navigation.navigate('BitsoLogin')}
                    >
                      <ButtonText>Reset Bitso API Key</ButtonText>
                    </Button>
                  </>
                ) : (
                  <Button
                    size={'lg'}
                    onPress={() => navigation.navigate('BitsoLogin')}
                  >
                    <ButtonText>Set Up Bitso</ButtonText>
                  </Button>
                )}
              </Box>
              <Box>
                {marketApps.includes('binance') ? (
                  <>
                    <HStack>
                      <Icon as={CheckCircleIcon} />
                      <Text>Binance</Text>
                    </HStack>
                    <Button
                      size={'lg'}
                      onPress={() => navigation.navigate('BinanceLogin')}
                    >
                      <ButtonText>Reset Binance API Key</ButtonText>
                    </Button>
                  </>
                ) : (
                  <Button
                    size={'lg'}
                    onPress={() => navigation.navigate('BinanceLogin')}
                  >
                    <ButtonText>Set Up Binance</ButtonText>
                  </Button>
                )}
              </Box>
              <ButtonGroup>
                <Button
                  variant={'outline'}
                  size={'md'}
                  onPress={() => navigation.navigate('About')}
                >
                  <ButtonText>About</ButtonText>
                </Button>

                <Button
                  variant={'outline'}
                  size={'md'}
                  onPress={() => Linking.openURL('https://discord.gg/kbx4jugR')}
                >
                  <ButtonText>Join Our Discord</ButtonText>
                </Button>
              </ButtonGroup>
              <ButtonGroup>
                <Button
                  size={'lg'}
                  onPress={() => navigation.navigate('DeleteUser')}
                >
                  <ButtonText>Delete Account</ButtonText>
                </Button>
                <Button
                  size={'lg'}
                  onPress={() => {
                    logout();
                    navigation.navigate('Home');
                  }}
                >
                  <ButtonText>Sign Out</ButtonText>
                </Button>
              </ButtonGroup>
            </VStack>
          </Center>
        </ScrollView>
      )}

      {user &&
        marketApps.length > 0 &&
        !configuredCryptoSettings &&
        !marketAppsLoading && <SelectCryptos />}
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: Platform.OS === 'android' ? 25 : 0,
  },
  center: {
    alignItems: 'center',
    padding: 20,
  },
  heading: {
    fontSize: 24,
    marginBottom: 10,
  },
  subHeading: {
    fontSize: 20,
    marginBottom: 20,
  },
  pressable: {
    width: '80%',
    paddingVertical: 12,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    borderWidth: 2,
    borderColor: '#secondary600',
    marginBottom: 20,
  },
  pressableMargin: {
    marginTop: 20,
  },
  hStack: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonText: {
    fontSize: 18,
  },
});
