import axios from 'axios';

import { BASE_URL, ENCRYPT_KEY, ENCRYPT_KEY_IV } from '@env';
import CryptoJS from 'crypto-js';
import CONFIG_FILE from './assets/configuration.json';

// Path to the JSON file

const configuration = JSON.parse(JSON.stringify(CONFIG_FILE));
const CRYPTO_MAPPED_TO_PRICE_DECIMAL_PRECISION =
  configuration.CRYPTO_MAPPED_TO_PRICE_DECIMAL_PRECISION;
const CRYPTO_MAPPED_TO_AMOUNT_DECIMAL_PRECISION =
  configuration.CRYPTO_MAPPED_TO_AMOUNT_DECIMAL_PRECISION;
const STOCK_CATEGORIES = configuration.list_of_categories;
const STOCK_CATEGORY_TO_COMPANIES_MAPPING = configuration.category_mapping;
const cryptoIconMap = {
  ETH: require('./assets/eth.png'),
  DOGE: require('./assets/doge.png'),
  BTC: require('./assets/btc.png'),
  AVAX: require('./assets/avax.png'),
  XLM: require('./assets/xlm.png'),
  BCH: require('./assets/bch.png'),
  LINK: require('./assets/link.png'),
  UNI: require('./assets/uni.png'),
  ETC: require('./assets/etc.png'),
  XTZ: require('./assets/xtz.png'),
  COMP: require('./assets/comp.png'),
  AAVE: require('./assets/aave.png'),
  LTC: require('./assets/ltc.png'),
  default: require('./assets/grin.png'),
  XRP: require('./assets/xrp.png'),
  POL: require('./assets/matic.png'),
  SOL: require('./assets/sol.png'),
  ADA: require('./assets/ada.png'),
  DOT: require('./assets/dot.png'),
  SHIB: require('./assets/shib.png'),
  FACTORY: require('./assets/factory-sm.png'),
};
const cryptoSmallIconMap = {
  ETH: require('./assets/eth-sm.png'),
  DOGE: require('./assets/doge-sm.png'),
  BTC: require('./assets/btc-sm.png'),
  AVAX: require('./assets/avax-sm.png'),
  XLM: require('./assets/xlm-sm.png'),
  BCH: require('./assets/bch-sm.png'),
  LINK: require('./assets/link-sm.png'),
  UNI: require('./assets/uni-sm.png'),
  ETC: require('./assets/etc-sm.png'),
  XTZ: require('./assets/xtz-sm.png'),
  COMP: require('./assets/comp-sm.png'),
  AAVE: require('./assets/aave-sm.png'),
  LTC: require('./assets/ltc-sm.png'),
  default: require('./assets/grin-sm.png'),
  XRP: require('./assets/xrp-sm.png'),
  POL: require('./assets/matic-sm.png'),
  SOL: require('./assets/sol-sm.png'),
  ADA: require('./assets/ada-sm.png'),
  DOT: require('./assets/dot-sm.png'),
  SHIB: require('./assets/shib-sm.png'),
  FACTORY: require('./assets/factory-sm.png'),
};

const investSettingsExplanations = {
  BOTH: 'SIDEWAYS MARKET🌊🌊🌊',
  BULL: 'BULL MARKET📈',
  BEAR: 'BEAR MARKET📉',
  NONE: 'DO NOT INVEST',
};

const CRYPTO_CONFIG = {
  names: configuration.names,
  fullNames: configuration.full_names,
};
let RELEVANCY_RANKING = configuration.RELEVANCY_RANKING;
let RH_SUPPORTED_CRYPTO = new Set(configuration.robinhood_crypto);
let RH_SUPPORTED_ETFS = new Set(configuration.etfs);
let RH_SUPPORTED_STOCKS = new Set(configuration.stocks);
let BINANCE_SUPPORTED_CRYPTO = new Set(configuration.binance_crypto);

let BITSO_USD_SUPPORTED_CRYPTO = new Set(configuration.bitso_crypto_usd);
let BITSO_MXN_SUPPORTED_CRYPTO = new Set(configuration.bitso_crypto_mxn);

// Function to sort a subset of the relevancy list (given as a Set)
function sortFinancialEntitiesByRelevancySet(subset) {
  // Convert the Set to an array to sort it
  const subsetArray = Array.from(subset);

  // Create a map of the relevancy index for each asset
  const relevancyIndex = RELEVANCY_RANKING.reduce((acc, item, index) => {
    acc[item] = index;
    return acc;
  }, {});

  // Sort the array based on its relevancy in the original relevancy list
  const sortedArray = subsetArray.sort((a, b) => {
    return relevancyIndex[a] - relevancyIndex[b];
  });

  // Return the sorted array as a Set (optional)
  return sortedArray;
}

function getInvestmentEntityType(entity) {
  if (RH_SUPPORTED_STOCKS.has(entity)) {
    return 'Stock';
  }
  return 'Crypto';
}

function getCryptosFromMarket(markets) {
  if (markets === 'robinhood') {
    return Array.from(RH_SUPPORTED_CRYPTO);
  }
  if (markets === 'bitso') {
    return Array.from(BITSO_USD_SUPPORTED_CRYPTO);
  }
  if (markets === 'binance') {
    return Array.from(BINANCE_SUPPORTED_CRYPTO);
  }
  return CRYPTO_CONFIG.names;
}
function getCryptosFromMarketList(markets) {
  let cryptos = new Set([]);

  if (markets.includes('robinhood_stocks')) {
    cryptos = new Set([...cryptos, ...RH_SUPPORTED_STOCKS]);
  }
  if (markets.includes('robinhood_crypto')) {
    cryptos = new Set([...cryptos, ...RH_SUPPORTED_CRYPTO]);
  }

  if (markets.includes('bitso')) {
    cryptos = new Set([...cryptos, ...BITSO_USD_SUPPORTED_CRYPTO]);
  }
  if (markets.includes('binance')) {
    cryptos = new Set([...cryptos, ...BINANCE_SUPPORTED_CRYPTO]);
  }
  cryptos = sortFinancialEntitiesByRelevancySet(cryptos);
  return cryptos;
}
function getInvestmentEntitiesForFilter(investmentList, marketList, filter) {
  let items_in_filter = getCryptosFromMarketListWithFilter(marketList, filter);
  const investment_list_filtered = [];
  for (let item of items_in_filter) {
    if (investmentList.has(item)) {
      investment_list_filtered.push(item);
    }
  }
  return investment_list_filtered;
}
function checkIfInvestingInFilter(investmentList, marketList, filter) {
  let items_in_filter = getCryptosFromMarketListWithFilter(marketList, filter);
  for (let item of items_in_filter) {
    if (investmentList.has(item)) {
      return true;
    }
  }
  return false;
}
function getCryptosFromMarketListWithFilter(markets, filter) {
  let cryptos = new Set([]);
  if (filter in STOCK_CATEGORY_TO_COMPANIES_MAPPING) {
    if (markets.includes('robinhood_stocks')) {
      cryptos = new Set(STOCK_CATEGORY_TO_COMPANIES_MAPPING[filter]);
    }
  }
  if (filter === 'stocks') {
    if (markets.includes('robinhood_stocks')) {
      cryptos = new Set(
        [...RH_SUPPORTED_STOCKS].filter((x) => !RH_SUPPORTED_ETFS.has(x)),
      );
    }
  }
  if (filter === 'etfs') {
    if (markets.includes('robinhood_stocks')) {
      cryptos = new Set(RH_SUPPORTED_ETFS);
    }
  }

  if (filter === 'crypto') {
    if (markets.includes('robinhood_crypto')) {
      cryptos = new Set([...cryptos, ...RH_SUPPORTED_CRYPTO]);
    }

    if (markets.includes('bitso')) {
      cryptos = new Set([...cryptos, ...BITSO_USD_SUPPORTED_CRYPTO]);
    }
    if (markets.includes('binance')) {
      cryptos = new Set([...cryptos, ...BINANCE_SUPPORTED_CRYPTO]);
    }
  }
  cryptos = sortFinancialEntitiesByRelevancySet(cryptos);
  return cryptos;
}
function getPossibleRobinhoodMarkets(market_list) {
  const resp = [];
  if (market_list !== undefined && market_list !== null) {
    if (market_list.includes('robinhood_stocks')) {
      resp.push('robinhood_stocks');
    }
    if (market_list.includes('robinhood_crypto')) {
      resp.push('robinhood_crypto');
    }
  }
  return resp;
}

const possible_suffixes = [''];
function encryptString(input) {
  var derived_key = CryptoJS.enc.Base64.parse(ENCRYPT_KEY);
  var iv = CryptoJS.enc.Utf8.parse(ENCRYPT_KEY_IV);

  var encryptedAES = CryptoJS.AES.encrypt(input, derived_key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
  });
  return encryptedAES.toString();
}
let current_suffix = '';
function getBaseURL() {
  let randomElement =
    possible_suffixes[Math.floor(Math.random() * possible_suffixes.length)];

  if (current_suffix === randomElement) {
    randomElement =
      possible_suffixes[Math.floor(Math.random() * possible_suffixes.length)];
  }
  current_suffix = randomElement;

  return BASE_URL + current_suffix;
}

function pythonBackendURL() {
  return BASE_URL + '/crypto_bot_http_backend';
  //return 'http://127.0.0.1:5000';
}

async function getExchangePrices() {
  try {
    const response = await axios.get(`${getBaseURL()}/get_exchange_prices`);
    return response;
  } catch (error) {
    console.error(error);
  }
}
async function deleteUser(user) {
  const tokenId = await user.getIdToken();

  try {
    const response = await axios.get(`${pythonBackendURL()}/delete_user`, {
      headers: {
        'google-token-id': tokenId,
        'Content-Type': 'text/plain',
        app: 'mobile',
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

////generate_robinhood_key
async function generateRobinhoodKey(user) {
  const tokenId = await user.getIdToken();

  try {
    const response = await axios.get(
      `${pythonBackendURL()}/generate_robinhood_key`,
      {
        headers: {
          'google-token-id': tokenId,
          'Content-Type': 'text/plain',
          app: 'mobile',
        },
      },
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function getBuyingPower(user, market) {
  const tokenId = await user.getIdToken();

  try {
    const response = await axios.get(
      `${pythonBackendURL()}/get_buying_power/${market}`,
      {
        headers: {
          'google-token-id': tokenId,
          'Content-Type': 'text/plain',
          app: 'mobile',
        },
      },
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function editPlay(user, data) {
  try {
    const tokenId = await user.getIdToken();

    const json = JSON.stringify(data);
    console.log('make play ');
    console.log(json);
    console.log('make play ');

    const response = await axios.post(
      `${pythonBackendURL()}/post_play/${data.id}`,
      json,
      {
        headers: {
          'google-token-id': tokenId,
          'Content-Type': 'text/plain',
          app: 'mobile',
        },
      },
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function deleteFund(user, fund_id) {
  try {
    const tokenId = await user.getIdToken();

    const response = await axios.get(
      `${pythonBackendURL()}/delete_funds/${fund_id}`,
      {
        headers: {
          'google-token-id': tokenId,
          app: 'mobile',
        },
      },
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function getCharts(time_period, crypto, currency) {
  try {
    const response = await axios.get(`${getBaseURL()}/get_charts`, {
      params: {
        timeperiod: time_period,
        crypto: crypto,
        currency: currency,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function getFunds2(user, market_app, asset, fundState, investmentFocus) {
  try {
    const tokenId = await user.getIdToken();

    const response = await axios.get(
      `${pythonBackendURL()}/get_funds2/${market_app}/${asset}/${fundState}/${investmentFocus}`,
      {
        headers: {
          'google-token-id': tokenId,
          app: 'mobile',
        },
      },
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function playFeed(user, crypto, playType, market) {
  try {
    const tokenId = await user.getIdToken();

    const response = await axios.get(`${getBaseURL()}/get_play_feed`, {
      headers: {
        'google-token-id': tokenId,
        app: 'mobile',
      },
      params: {
        crypto: crypto,
        playtype: playType,
        market: market,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function getAppResultsFeed(timePeriod) {
  try {
    const response = await axios.get(`${getBaseURL()}/get_app_results_feed`, {
      params: {
        crypto: 'ALL',
        playtype: 'ALL',
        market: 'ALL',
        timeperiod: timePeriod,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function getOpenFundCounts(user) {
  try {
    const tokenId = await user.getIdToken();

    const response = await axios.get(`${getBaseURL()}/get_open_fund_counts`, {
      headers: {
        'google-token-id': tokenId,
        app: 'mobile',
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function reuseFund(user, fundid) {
  try {
    const tokenId = await user.getIdToken();

    const response = await axios.get(`${getBaseURL()}/reuse_fund`, {
      headers: {
        'google-token-id': tokenId,
        app: 'mobile',
      },
      params: {
        fundid: fundid,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function getPlay(playId) {
  try {
    const response = await axios.get(`${getBaseURL()}/get_play`, {
      params: {
        playid: playId,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}
async function getTotalGainsForTimePeriod(user, timePeriod) {
  try {
    const tokenId = await user.getIdToken();

    const response = await axios.get(`${getBaseURL()}/get_total_gains`, {
      headers: {
        'google-token-id': tokenId,
        app: 'mobile',
      },
      params: {
        timeperiod: timePeriod,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}
async function getTotalGainsForAllTimePeriod(timePeriod) {
  try {
    const response = await axios.get(
      `${getBaseURL()}/get_total_gains_for_all`,
      {
        params: {
          timeperiod: timePeriod,
        },
      },
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function getGlobalBetSettings(user) {
  try {
    const tokenId = await user.getIdToken();

    const response = await axios.get(`${getBaseURL()}/get_bet_settings`, {
      headers: {
        'google-token-id': tokenId,
        app: 'mobile',
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}
//get_market_apps

async function getMarketApps(user) {
  try {
    const tokenId = await user.getIdToken();

    const response = await axios.get(`${getBaseURL()}/get_market_apps`, {
      headers: {
        'google-token-id': tokenId,
        app: 'mobile',
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function acceptTerms(user) {
  try {
    const tokenId = await user.getIdToken();

    const response = await axios.get(`${getBaseURL()}/accept_terms`, {
      headers: {
        'google-token-id': tokenId,
        app: 'mobile',
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function getCryptoInvestSetttings(user) {
  try {
    const tokenId = await user.getIdToken();

    const response = await axios.get(
      `${getBaseURL()}/get_crypto_invest_settings`,
      {
        headers: {
          'google-token-id': tokenId,
          app: 'mobile',
        },
      },
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function postCryptoInvestSettingsList(
  user,
  cryptos_to_invest,
  cryptos_to_not_invest,
) {
  try {
    const tokenId = await user.getIdToken();

    const data = {
      cryptos_to_invest: cryptos_to_invest,
      cryptos_to_not_invest: cryptos_to_not_invest,
    };

    const json = JSON.stringify(data);
    console.log('make play ');
    console.log(json);
    console.log('make play ');

    const response = await axios.post(
      `${pythonBackendURL()}/post_crypto_invest_setttings2`,
      json,
      {
        headers: {
          'google-token-id': tokenId,
          'Content-Type': 'text/plain',
          app: 'mobile',
        },
      },
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function postCryptoInvestSettings(user, crypto, invest_mode) {
  try {
    const tokenId = await user.getIdToken();

    const data = {
      cryptos: [crypto],
      invest_mode: invest_mode,
    };

    const json = JSON.stringify(data);
    console.log('make play ');
    console.log(json);
    console.log('make play ');

    const response = await axios.post(
      `${pythonBackendURL()}/post_crypto_invest_setttings`,
      json,
      {
        headers: {
          'google-token-id': tokenId,
          'Content-Type': 'text/plain',
          app: 'mobile',
        },
      },
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function postFunds(user, data) {
  try {
    const tokenId = await user.getIdToken();

    const json = JSON.stringify(data);
    console.log('post funds ');
    console.log(json);
    console.log('post funds ');

    const response = await axios.post(
      `${pythonBackendURL()}/post_funds`,
      json,
      {
        headers: {
          'google-token-id': tokenId,
          'Content-Type': 'text/plain',
          app: 'mobile',
        },
      },
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}
const getPossibleBrokers = (market_list) => {
  const resp = [];
  if (market_list.includes('robinhood')) {
    resp.push('robinhood');
  }
  if (market_list.includes('bitso')) {
    resp.push('bitso');
  }
  if (market_list.includes('binance')) {
    resp.push('binance');
  }
  return resp;
};

async function getCryptoDetails(user, crypto, timeperiod) {
  try {
    const tokenId = await user.getIdToken();

    const response = await axios.get(`${getBaseURL()}/get_crypto_details`, {
      headers: {
        'google-token-id': tokenId,
        app: 'mobile',
      },
      params: { crypto: crypto, timeperiod: timeperiod },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function getLapsedFunds(user) {
  try {
    const tokenId = await user.getIdToken();

    const response = await axios.get(`${getBaseURL()}/get_lapsed_funds`, {
      headers: {
        'google-token-id': tokenId,
        app: 'mobile',
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function getFunds(user, market_app, asset) {
  try {
    const tokenId = await user.getIdToken();

    const response = await axios.get(
      `${pythonBackendURL()}/get_funds/${market_app}/${asset}`,
      {
        headers: {
          'google-token-id': tokenId,
          app: 'mobile',
        },
      },
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

//post_pattern_day_trading_override
async function postDayTradingOverride(user, bet_setting) {
  try {
    const tokenId = await user.getIdToken();

    const data = {
      override: bet_setting,
    };

    const json = JSON.stringify(data);
    console.log('make play ');
    console.log(json);
    console.log('make play ');

    const response = await axios.post(
      `${pythonBackendURL()}/post_pattern_day_trading_override`,
      json,
      {
        headers: {
          'google-token-id': tokenId,
          'Content-Type': 'text/plain',
          app: 'mobile',
        },
      },
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}
async function postTradingBotOperationDaySetting(user, ticker, bet_setting) {
  try {
    const tokenId = await user.getIdToken();

    const data = {
      ticker: ticker,
      operation: bet_setting,
    };

    const json = JSON.stringify(data);
    console.log('make play ');
    console.log(json);
    console.log('make play ');

    const response = await axios.post(
      `${pythonBackendURL()}/post_trading_bot_operation_day_setting`,
      json,
      {
        headers: {
          'google-token-id': tokenId,
          'Content-Type': 'text/plain',
          app: 'mobile',
        },
      },
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function postPlaySettings(user, bet_setting) {
  try {
    const tokenId = await user.getIdToken();

    const data = {
      bet_setting: bet_setting,
    };

    const json = JSON.stringify(data);
    console.log('make play ');
    console.log(json);
    console.log('make play ');

    const response = await axios.post(
      `${pythonBackendURL()}/post_bet_settings`,
      json,
      {
        headers: {
          'google-token-id': tokenId,
          'Content-Type': 'text/plain',
          app: 'mobile',
        },
      },
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function makePlay(user, data) {
  const tokenId = await user.getIdToken();

  try {
    const json = JSON.stringify(data);
    console.log('make play ');
    console.log(json);
    console.log('make play ');

    const response = await axios.post(`${pythonBackendURL()}/make_play`, json, {
      headers: {
        'google-token-id': tokenId,
        'Content-Type': 'text/plain',
        app: 'mobile',
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}
//post_user
async function postUser(user, data) {
  const tokenId = await user.getIdToken();
  try {
    const json = JSON.stringify(data);
    const response = await axios.post(`${pythonBackendURL()}/post_user`, json, {
      headers: {
        'google-token-id': tokenId,
        'Content-Type': 'text/plain',
        app: 'mobile',
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

///

async function getNews(crypto_name) {
  try {
    const response = await axios.get(
      `https://cryptonews-api.com/api/v1?tickers=${crypto_name}&items=10&page=1&token=11pvsamc4gyxa2knydmg2kejcfikkwq0dwkls2na`,
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function robinhoodSignIn(user, data) {
  try {
    const tokenId = await user.getIdToken();
    data['robinhood_email'] = encryptString(data['robinhood_email']);
    data['robinhood_pw'] = encryptString(data['robinhood_pw']);

    const json = JSON.stringify(data);
    const response = await axios.post(
      `${pythonBackendURL()}/robinhood_sign_in`,
      json,
      {
        headers: {
          'google-token-id': tokenId,
          'Content-Type': 'text/plain',
          app: 'mobile',
        },
      },
    );
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }
    console.error(error);
  }
}

async function robinhoodTwoFa(user, data) {
  try {
    const tokenId = await user.getIdToken();

    const json = JSON.stringify(data);
    const response = await axios.post(
      `${pythonBackendURL()}/robinhood_two_fa`,
      json,
      {
        headers: {
          'google-token-id': tokenId,
          'Content-Type': 'text/plain',
          app: 'mobile',
        },
      },
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

function hideEmail(email) {
  const words = email.split('@');
  const hiddenEmail = words[0].substring(0, 1) + '*****';
  return hiddenEmail + '@' + words[1];
}

export const dashboardAPI = {
  CRYPTO_MAPPED_TO_AMOUNT_DECIMAL_PRECISION,
  CRYPTO_MAPPED_TO_PRICE_DECIMAL_PRECISION,
  CRYPTO_CONFIG,
  playFeed,
  getPlay,
  getCryptoInvestSetttings,
  getGlobalBetSettings,
  getMarketApps,
  postFunds,
  getFunds2,
  postCryptoInvestSettings,
  postPlaySettings,
  getTotalGainsForTimePeriod,
  getTotalGainsForAllTimePeriod,
  getOpenFundCounts,
  deleteFund,
  getCharts,
  editPlay,
  getExchangePrices,
  getFunds,
  makePlay,
  robinhoodTwoFa,
  robinhoodSignIn,
  postUser,
  getNews,
  getCryptoDetails,
  hideEmail,
  postCryptoInvestSettingsList,
  getLapsedFunds,
  deleteUser,
  getBuyingPower,
  acceptTerms,
  getAppResultsFeed,
  generateRobinhoodKey,
  encryptString,
  getCryptosFromMarketList,
  getCryptosFromMarket,
  getCryptosFromMarketListWithFilter,
  cryptoIconMap,
  cryptoSmallIconMap,
  reuseFund,
  investSettingsExplanations,
  RH_SUPPORTED_STOCKS,
  RH_SUPPORTED_ETFS,
  getPossibleBrokers,
  getPossibleRobinhoodMarkets,
  postDayTradingOverride,
  getInvestmentEntityType,
  postTradingBotOperationDaySetting,
  getInvestmentEntitiesForFilter,
  checkIfInvestingInFilter,
  STOCK_CATEGORY_TO_COMPANIES_MAPPING,
  STOCK_CATEGORIES,
};
