import {
  Avatar,
  AvatarFallbackText,
  AvatarImage,
  Box,
  Center,
  Heading,
  HStack,
  Badge,
  BadgeText,
  ArrowUpIcon,
  PaperclipIcon,
  Icon,
  Button,
  ButtonText,
  Text,
  Image,
} from '@gluestack-ui/themed';
import { StyleSheet } from 'react-native';
import React from 'react';
import Ionicons from 'react-native-vector-icons/Ionicons';
import { dashboardAPI as DashboardApi, dashboardAPI } from '../../DashboardAPI';

export default function CryptoAvatar(params) {
  if (params.crypto === 'cash') {
    return (
      <Avatar size={'md'}>
        <Ionicons name={'cash-outline'} color={'white'} />
      </Avatar>
    );
  }
  let value = null;
  if (DashboardApi.RH_SUPPORTED_STOCKS.has(params.crypto)) {
    value = dashboardAPI.cryptoIconMap.FACTORY;
  } else {
    value = dashboardAPI.cryptoIconMap[params.crypto];
  }
  if (value === undefined) {
    console.log('heyyyyyy');
    value = dashboardAPI.cryptoIconMap['default'];
  }
  let size = 'sm';
  if (params.size !== undefined && params.size === 'sm') {
    size = 'xs';
  }

  return (
    <Image
      alt={params.crypto + ' logo'}
      size={size}
      borderRadius="$none"
      source={value}
    />
  );
}

const styles = StyleSheet.create({
  card: {
    // flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    // paddingTop: StatusBar.currentHeight,
    marginRight: 20,
    marginLeft: 20,
    marginTop: 20,
    paddingBottom: 20,
    paddingTop: 20,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#fff',
  },
});
