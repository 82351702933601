import React, { useContext, useEffect } from 'react';
import { Text, Heading, Center, Image } from '@gluestack-ui/themed';
import { AuthContext } from '../AuthProvider';
import HomeStats from './HomeStats';
import Ionicons from 'react-native-vector-icons/Ionicons';
import PlayFeed from './PlayFeed';
import Settings from './Settings';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import Loading from './shared/Loading';
import { useNavigation } from '@react-navigation/native';
import Login from './shared/Login';
import LoginScreen from './shared/LoginScreen';
import { SafeAreaView } from 'react-native';
import Terms from './Terms';
import RobinhoodLogin from './RobinhoodLogin';

const Tab = createBottomTabNavigator();

export default function Home() {
  const {
    user,
    marketApps,
    logout,
    login,
    authLoading,
    marketAppsLoading,
    configuredCryptoSettings,
    acceptedTerms,
    rhSessionExpirationDate,
    rhEmail,
    ignoreRhSessionExpirationDate,
  } = useContext(AuthContext);
  const navigation = useNavigation();
  useEffect(() => {
    (async () => {
      ///TODO
    })();
  }, [
    user,
    marketApps,
    authLoading,
    marketAppsLoading,
    acceptedTerms,
    rhSessionExpirationDate,
    rhEmail,
    ignoreRhSessionExpirationDate,
  ]);

  let logo = require('../assets/LOGO2.png');

  if (authLoading) {
    return (
      <>
        <Center>
          <Image
            mt={60}
            alt={'logo'}
            size={'lg'}
            borderRadius="$none"
            source={logo}
          />

          <Text bold={true} color={'$primary500'}>
            CryptoPilot.app
          </Text>
          <Loading />
        </Center>
      </>
    );
  }

  if (!user) {
    return <LoginScreen />;
  }
  if (!acceptedTerms) {
    return <Terms />;
  }

  if (marketApps.length === 0 || !configuredCryptoSettings) {
    return (
      <SafeAreaView style={{ flex: 8 }}>
        <Settings />
      </SafeAreaView>
    );
  }
  //If rh session is expired???
  if (
    !ignoreRhSessionExpirationDate &&
    marketApps.includes('robinhood_stocks') &&
    rhSessionExpirationDate < new Date()
  ) {
    return <RobinhoodLogin ignorebutton />;
  }

  return (
    <Tab.Navigator>
      <Tab.Screen
        name="CryptoPilot"
        component={HomeStats}
        options={{
          headerShown: false,
          //labelPosition: 'beside-icon',
          //tabBarLabel: ({}) => <></>),
          tabBarShowLabel: false,
          tabBarIcon: ({ color, size, focused }) => (
            <>
              <Ionicons
                name={'home-outline'}
                size={size}
                color={focused ? '#348352' : color}
              />
              <Text color={focused ? '#348352' : color} size="2xs">
                Dashboard
              </Text>
            </>
          ),
        }}
      />
      <Tab.Screen
        name="Plays"
        component={PlayFeed}
        options={{
          headerShown: false,
          //tabBarLabel: ({}) => <Text size="2xs">Plays</Text>,
          tabBarShowLabel: false,
          tabBarIcon: ({ color, size, focused }) => (
            <>
              <Ionicons
                name={'cash-outline'}
                size={size}
                color={focused ? '#348352' : color}
              />
              <Text color={focused ? '#348352' : color} size="2xs">
                Plays
              </Text>
            </>
          ),
        }}
      />
      <Tab.Screen
        name="Settings"
        component={Settings}
        options={{
          tabBarShowLabel: false,
          //tabBarLabel: ({}) => <Text size="2xs">Settings</Text>,
          tabBarIcon: ({ color, size, focused }) => (
            <>
              <Ionicons
                name={'settings-outline'}
                size={size}
                color={focused ? '#348352' : color}
              />
              <Text color={focused ? '#348352' : color} size="2xs">
                Settings
              </Text>
            </>
          ),
        }}
      />
    </Tab.Navigator>
  );
}
